<template>
	<div v-if="show">
        <b-card no-body>
            <b-tabs card fill pills @changed="handleTabChange">
                <b-tab :title="$t('title.chart')" v-if="$global.hasPermission('orderdraftscanseechartsc')" :active="$global.hasPermission('orderdraftscanseechartsc')">
                    <b-card-text>
                        <form @submit.prevent="handleReportFilterSubmit">
                            <b-row>
                                <b-col sm="8">
                                    <b-row>
                                        <b-col sm="12" md="4" lg="4">
                                            <b-form-group :invalid-feedback="formErrors.first('countries')">
                                                <treeselect
                                                    :multiple="true"
                                                    :options="dropdowns.countries"
                                                    :placeholder="$t('input.destinationCountries')"
                                                    v-model="chartFormFields.countries"
                                                    :class="[{'invalid is-invalid': (formErrors.has('countries'))}]"
                                                />
                                            </b-form-group>
                                        </b-col>
                                        <b-col sm="12" md="4" lg="4">
                                            <b-form-group :invalid-feedback="formErrors.first('brands')">
                                                <treeselect
                                                    :multiple="true"
                                                    :options="dropdowns.brands"
                                                    :placeholder="$t('input.brands')"
                                                    v-model="chartFormFields.brands"
                                                    :class="[{'invalid is-invalid': (formErrors.has('brands'))}]"
                                                />
                                            </b-form-group>
                                        </b-col>
                                        <b-col sm="12" md="4" lg="4">
                                            <b-form-group :invalid-feedback="formErrors.first('models')">
                                                <treeselect
                                                    :multiple="true"
                                                    :options="dropdowns.models"
                                                    :placeholder="$t('input.models')"
                                                    v-model="chartFormFields.models"
                                                    :class="[{'invalid is-invalid': (formErrors.has('models'))}]"
                                                />
                                            </b-form-group>
                                        </b-col>
                                        <b-col sm="12" class="text-danger">
                                            <small>{{$t('msc.pleaseNoteThatTheVolumesAreIndicativeAndNonBinding')}}</small>
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <b-col sm="12" md="1" lg="1">
                                    <b-button
                                        block
                                        size="sm"
                                        type="submit"
                                        variant="primary"
                                        :disabled="global.pendingRequests > 0"
                                        v-b-tooltip.hover :title="$t('button.title.filterRecords')"
                                    >
                                        <clip-loader style="display: inline" :loading="true" color="#fff"
                                                     size="12px"
                                                     v-if="global.pendingRequests > 0"></clip-loader>
                                        {{$t('button.apply')}}
                                    </b-button>
                                </b-col>
                                <b-col v-if="0 == 1" sm="3" class="text-right">
                                    <b-button size="sm" type="button" variant="info" @click="handlePrevClick()"
                                              :disabled="global.pendingRequests > 0">
                                        <clip-loader style="display: inline" :loading="true" color="#fff"
                                                     size="12px"
                                                     v-if="global.pendingRequests > 0"></clip-loader>
                                        {{$t('button.previous')}}
                                    </b-button>
                                    <b-button size="sm" type="button" variant="secondary"
                                              @click="handleNextClick()"
                                              class="ml-2"
                                              :disabled="global.pendingRequests > 0">
                                        <clip-loader style="display: inline" :loading="true" color="#fff"
                                                     size="12px"
                                                     v-if="global.pendingRequests > 0"></clip-loader>
                                        {{$t('button.next')}}
                                    </b-button>
                                </b-col>
                                <b-col sm="12" md="2" lg="2" v-if="$global.hasPermission('orderdraftscanconfirmweeksc')">
                                    <b-button block @click="handleConfirmWeeks" variant="success" size="sm"
                                              :disabled="global.pendingRequests > 0"
                                              :title="((accept_full > 0 || accept_contractual > 0 || accept_contractual_difference > 0 || cancel > 0) ? $t('button.title.showStatus') : $t('button.title.confirmAtn'))"
                                              v-b-tooltip.hover>
                                        <clip-loader style="display: inline" :loading="true" color="#fff"
                                                     size="12px" class="mr-1"
                                                     v-if="global.pendingRequests > 0"></clip-loader>
                                        <i class="fa fa-check-square-o"></i>{{ ((accept_full > 0 || accept_contractual > 0 || accept_contractual_difference > 0 || cancel > 0) ? $t('button.showStatus') : $t('button.confirmAtn')) }}
                                    </b-button>
                                </b-col>
                                <b-col sm="1" class="pl-0">
                                    <b-button size="sm" :title="$t('button.title.exportExcel')"
                                              variant="outline-info"
                                              @click="handleExcelExportClick" v-b-tooltip.hover
                                              v-if="$global.hasPermission('orderdraftscanseechartsc')">
                                        <i class="fa fa-file-excel-o"></i>
                                    </b-button>
                                </b-col>
                            </b-row>
                        </form>
                        <template v-if="chartDataKeys.length > 0">
                            <b-row class="p-3 bg-gray-3">
                                <b-col sm="3">
                                    <h5 class="m-0 p-0">
                                        {{(startYear === endYear) ? startYear : `${startYear}-${endYear}`}}
                                    </h5>
                                </b-col>
                                <b-col sm="1" v-for="(label, index) in chartDataKeys" :key="index" class="text-right">
                                    <h5 :class="(_.find(weekAcceptance, {identifier: label})) ? _.find(weekAcceptance, {identifier: label}).class + ' m-0 p-0 fs-13' : 'm-0 p-0 fs-13' " :title="(_.find(weekAcceptance, {identifier: label})) ? _.find(weekAcceptance, {identifier: label}).title : '' ">
                                        <span :style="(_.find(weekAcceptance, {identifier: label}) && _.find(weekAcceptance, {identifier: label}).class ? 'border-bottom: 3px solid' : '')">{{label}}</span>
                                    </h5>
                                </b-col>
                            </b-row>
                            <template v-for="(country, index) in chartDataModelGrouped">
                                <b-row class="p-3 bg-gray-3" v-if="country.items.length > 0">
                                    <b-col sm="12" v-b-toggle="'country-'+index">
                                        <h5 class="m-0 p-0">
                                            <span class="when-open"><i class="fe fe-minus"></i></span><span class="when-closed"><i class="fe fe-plus"></i></span>
                                            {{country.country}}
                                            <span> <country-sum :country="country"></country-sum> </span>
                                        </h5>
                                    </b-col>
                                    <b-col sm="12">
                                        <b-collapse :id="'country-'+index" visible>
                                            <div class="p-2 bg-gray-2">
                                                <b-row v-for="(pil, pilIndex) in country.items" :key="pilIndex">
                                                    <b-col sm="12" v-b-toggle="'model-'+(index+'-'+pilIndex)">
                                                        <span class="text-size-1-2em">
                                                            <span class="when-open"><i class="fe fe-minus"></i></span><span class="when-closed"><i class="fe fe-plus"></i></span>
                                                            {{pil.city}}
                                                            <span> <pick-up-sum :item="pil.items"></pick-up-sum> </span>
                                                        </span>
                                                    </b-col>
                                                    <b-col sm="12">
                                                        <b-collapse :id="'model-'+(index+'-'+pilIndex)" class="bg-gray-1" visible>
                                                            <div class="p-2">
                                                                <b-row v-for="(mdl, mdlIndex) in pil.items" :key="mdlIndex">
                                                                    <b-col sm="3">{{mdl.model_code}}</b-col>
                                                                    <template v-for="(week, weekIndex) in chartDataKeys">
                                                                        <b-col sm="1" class="text-right">
                                                                            {{ (_.find(mdl.items, {identifier: week})) ? _.find(mdl.items, {identifier: week}).total_orders : 0 }}
                                                                        </b-col>
                                                                    </template>
                                                                </b-row>
                                                            </div>
                                                        </b-collapse>
                                                    </b-col>
                                                </b-row>
                                            </div>
                                        </b-collapse>
                                    </b-col>
                                </b-row>
                            </template>
                            <b-col>
                                <b-row>
                                    <b-col><strong>{{ $t('title.lastUpdatedAt') }}:</strong> {{(lastOrderDraft &&
                                    lastOrderDraft.updated_at ?
                                        $global.utcDateToLocalDate(lastOrderDraft.updated_at) : '')}}
                                    </b-col>
                                </b-row>
                            </b-col>
                        </template>
                    </b-card-text>
                    <!-- # Start Confirm Weeks -->
                    <div class="confirm-weeks-operation">
                        <a-drawer
                            placement="right"
                            :width="'1150px'"
                            :wrapStyle="{overflow: 'auto',paddingBottom: '108px'}"
                            :closable="false"
                            @close="handleConfirmWeeksFormOperationClose()"
                            :visible="confirmWeeksFormVisible"
                            :zIndex="10"
                            :title="((accept_full > 0 || accept_contractual > 0 || accept_contractual_difference > 0 || cancel > 0) ? $t('button.showStatus') : $t('button.confirmAtn'))"
                        >
                            <b-row>
                                <b-col cols="12" v-show="global.pendingRequests > 0">
                                    <a-skeleton active :paragraph="{ rows: 5 }"/>
                                </b-col>
                                <b-col cols="12" v-show="global.pendingRequests <= 0">
                                    <b-row>
                                        <b-col sm="12" v-if="dataSourceRequests.length < 1">
                                            <span class="text-danger mb-2">{{$t('msc.noRequestForTheFollowingWeeks')}}</span>
                                        </b-col>
                                        <b-col sm="12" v-if="accept_full > 0 || accept_contractual > 0 || accept_contractual_difference > 0 || cancel > 0" class="mb-5">
                                            <template v-if="accept_full > 0">
                                                <b-badge :title="$t('title.status')" variant="success">
                                                    {{$t('title.atnFullyConfirmed')}}
                                                </b-badge>
                                            </template>
                                            <template v-if="accept_contractual > 0">
                                                <b-badge :title="$t('title.status')" variant="info">
                                                    {{$t('title.atnPartiallyAccepted')}}
                                                </b-badge>
                                            </template>
                                            <template v-if="accept_contractual_difference > 0">
                                                <b-badge :title="$t('title.status')" variant="warning">
                                                    {{$t('title.atnFullyAcceptedWithOffer')}}
                                                </b-badge>
                                                <br>
                                                <b-badge :title="$t('title.eflStatus')" v-if="efl_status == 'efl_approval_pending'" variant="info">
                                                    {{$t('title.eflApprovalPending') }}
                                                </b-badge>
                                                <b-badge :title="$t('title.eflStatus')" v-if="efl_status == 'rejected'" variant="danger">
                                                    {{$t('title.rejected') }}
                                                </b-badge>
                                                <b-badge :title="$t('title.eflStatus')" v-if="efl_status == 'approved'" variant="success">
                                                    {{$t('title.approved') }}
                                                </b-badge>
                                                <p></p>
                                                <div class="table-responsive">
                                                    <table class="table table-hover">
                                                        <thead style="text-align: left">
                                                        <tr>
                                                            <th>Route</th>
                                                            <th>Extra Cost per Vehicle</th>
                                                            <th>Total</th>
                                                            <th>Currency</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr v-for="(dsr, dsrIndex) in dataSourceRequestsExtraCost">
                                                            <td>{{dsr.price.route.from_location.city}} - {{dsr.price.route.to_location.city}}</td>
                                                            <td>{{dsr.extra_cost_per_vehicle}}</td>
                                                            <td>{{dsr.total_cost}}</td>
                                                            <td>{{dsr.currency}}</td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </template>
                                            <template v-if="cancel > 0">
                                                <b-badge :title="$t('title.status')" variant="danger">
                                                    {{$t('title.atnRejected')}}
                                                </b-badge>
                                            </template>
                                        </b-col>
                                        <b-col sm="6" v-for="(label, index) in dataKeys" :key="index" class="text-left">
                                            <h5 class="m-0 p-0 mb-2" v-if="(_.find(dataSourceRequests, {week_number: label}))">
                                                Week: {{label}}
                                            </h5>
                                            <b-row v-for="(ds, dsIndex) in dataSourceRequests" :key="dsIndex" v-if="ds.week_number === label" class="border-bottom mb-1">
                                                <b-col sm="12"><strong>{{ds.price.route.from_location.city}} - {{ds.price.route.to_location.city}} </strong></b-col>
                                                <b-col sm="6">Requested Loads (Vehicles):</b-col>
                                                <b-col sm="6">{{parseFloat(ds.total_requested_loads).toFixed(1)}} ({{ds.total_requested_vehicles}})</b-col>
                                                <b-col sm="6">Committed Loads (Vehicles):</b-col>
                                                <b-col sm="6">{{ds.contractual_loads}} ({{ds.contractual_vehicles}})</b-col>
                                                <b-col sm="6">Difference [Flex: {{ds.price.flexibility}}%] (Vehicles):</b-col>
                                                <b-col sm="6">
                                                    <span v-if="ds.total_requested_loads - ds.contractual_loads == 0" class="text-success">0 (0)</span>
                                                    <span v-if="ds.total_requested_loads - ds.contractual_loads > 0">
                                                            <span :class="((parseInt(ds.total_requested_vehicles) - (Math.round(parseFloat((ds.contractual_loads * ds.price.flexibility / 100) + parseInt(ds.contractual_loads)).toFixed(1)) * ds.price.loading_factors)) > 0 ? 'text-danger' : 'text-info')" v-if="ds.total_requested_loads - ds.contractual_loads > parseFloat(ds.contractual_loads * ds.price.flexibility / 100).toFixed(1)">
                                                                {{ parseFloat(ds.total_requested_loads - Math.round(parseFloat((ds.contractual_loads * ds.price.flexibility / 100) + parseInt(ds.contractual_loads)).toFixed(1))).toFixed(1) }}
                                                                ({{ parseInt(ds.total_requested_vehicles) - (Math.round(parseFloat((ds.contractual_loads * ds.price.flexibility / 100) + parseInt(ds.contractual_loads)).toFixed(1)) * ds.price.loading_factors)}})
                                                            </span>
                                                            <span class="text-success" v-else>0 (0)</span>
                                                        </span>
                                                    <span v-if="ds.total_requested_loads - ds.contractual_loads < 0" class="success">
                                                            <span class="text-info" v-if="ds.contractual_loads - ds.total_requested_loads > parseFloat(ds.contractual_loads * ds.price.flexibility / 100).toFixed(1)">
                                                                {{ parseFloat(ds.total_requested_loads - Math.round(parseFloat(parseInt(ds.contractual_loads) - (ds.contractual_loads * ds.price.flexibility / 100)).toFixed(1))).toFixed(1) }}
                                                                ({{ parseInt(ds.total_requested_vehicles) - (Math.round(parseInt(ds.contractual_loads) - parseFloat(ds.contractual_loads * ds.price.flexibility / 100)) * ds.price.loading_factors) }})
                                                            </span>
                                                            <span class="text-success" v-else>0 (0)</span>
                                                        </span>
                                                </b-col>
                                                <b-col sm="12">
                                                    <b-card-header v-b-toggle.price-models class="p-0">
                                                        <p class="mb-0">
                                                                    <span class="badge badge-primary">
                                                                        {{$t('title.models')}}
                                                                    </span>
                                                        </p>
                                                    </b-card-header><!-- /.p-0-->

                                                    <b-collapse id="price-models">
                                                        <div class="bg-light pt-1 pb-1 pl-3 pr-3">
                                                            <b-row>
                                                                <span>{{ds.model_names}}</span>
                                                            </b-row><!--/b-row-->
                                                        </div><!-- /.bg-light -->
                                                    </b-collapse><!-- /#price-documents-->
                                                </b-col>
                                            </b-row>
                                        </b-col>
                                    </b-row><!--/b-row-->
                                </b-col><!--/b-col-->
                                <div class="drawer-footer">
                                    <b-row>
                                        <b-col sm="12">
                                            <b-button
                                                v-if="dataSourceRequests.length > 0 && (accept_full < 1 && accept_contractual < 1 && accept_contractual_difference < 1 && cancel < 1)"
                                                size="sm"
                                                type="submit"
                                                variant="primary"
                                                accept_type="1"
                                                @click="handleConfirmWeeksSubmit(3)"
                                                :disabled="global.pendingRequests > 0"
                                                v-b-tooltip.hover :title="$t('button.title.contractualAcceptance')"
                                            >
                                                <clip-loader style="display: inline" :loading="true" color="#fff"
                                                             size="12px"
                                                             v-if="global.pendingRequests > 0"></clip-loader>
                                                <i class="fa fa-save mr-1"></i>
                                                {{$t('button.contractualAcceptance')}}
                                            </b-button>
                                            <b-button
                                                v-if="dataSourceRequests.length > 0 && (accept_full < 1 && accept_contractual < 1 && accept_contractual_difference < 1 && cancel < 1)"
                                                class="ml-1"
                                                size="sm"
                                                type="submit"
                                                variant="primary"
                                                accept_type="1"
                                                @click="handleConfirmWeeksSubmit(1)"
                                                :disabled="global.pendingRequests > 0"
                                                v-b-tooltip.hover :title="$t('button.title.fullAcceptanceWithExtraCost')"
                                            >
                                                <clip-loader style="display: inline" :loading="true" color="#fff"
                                                             size="12px"
                                                             v-if="global.pendingRequests > 0"></clip-loader>
                                                <i class="fa fa-save mr-1"></i>
                                                {{$t('button.fullAcceptanceWithExtraCost')}}
                                            </b-button>
                                            <b-button
                                                v-if="dataSourceRequests.length > 0 && (accept_full < 1 && accept_contractual < 1 && accept_contractual_difference < 1 && cancel < 1)"
                                                size="sm"
                                                class="ml-1"
                                                type="submit"
                                                variant="primary"
                                                accept_type="1"
                                                @click="handleConfirmWeeksSubmit(2)"
                                                :disabled="global.pendingRequests > 0"
                                                v-b-tooltip.hover :title="$t('button.title.fullAcceptanceWithOffer')"
                                            >
                                                <clip-loader style="display: inline" :loading="true" color="#fff"
                                                             size="12px"
                                                             v-if="global.pendingRequests > 0"></clip-loader>
                                                <i class="fa fa-save mr-1"></i>
                                                {{$t('button.fullAcceptanceWithOffer')}}
                                            </b-button>
                                            <b-button
                                                v-if="dataSourceRequests.length > 0 && (accept_full < 1 && accept_contractual < 1 && accept_contractual_difference < 1 && cancel < 1)"
                                                size="sm"
                                                class="ml-1"
                                                type="submit"
                                                variant="primary"
                                                accept_type="1"
                                                @click="handleConfirmWeeksSubmit(4)"
                                                :disabled="global.pendingRequests > 0"
                                                v-b-tooltip.hover :title="$t('button.title.reject')"
                                            >
                                                <clip-loader style="display: inline" :loading="true" color="#fff"
                                                             size="12px"
                                                             v-if="global.pendingRequests > 0"></clip-loader>
                                                <i class="fa fa-save mr-1"></i>
                                                {{$t('button.reject')}}
                                            </b-button>
                                            <b-button variant="warning" class="ml-1"
                                                      size="sm" @click="handleConfirmWeeksFormOperationClose()"
                                                      v-b-tooltip.hover :title="$t('button.title.cancel')">
                                                <i class="fa fa-arrow-left mr-1"></i> {{$t('button.cancel')}}
                                            </b-button>
                                        </b-col>
                                    </b-row>
                                </div>
                            </b-row><!--/b-row-->
                        </a-drawer>
                    </div><!--/.confirm-weeks-operation-->

                    <div class="double-confirm-weeks-operation">
                        <a-drawer
                            placement="right"
                            :width="'960px'"
                            :wrapStyle="{overflow: 'auto',paddingBottom: '108px'}"
                            :closable="false"
                            @close="handleDoubleConfirmWeeksFormOperationClose()"
                            :visible="doubleConfirmWeeksFormVisible"
                            :zIndex="10"
                            :title="doubleConfirmOperationTitle"
                        >
                            <b-row>
                                <b-col cols="12" v-show="global.pendingRequests > 0">
                                    <a-skeleton active :paragraph="{ rows: 5 }"/>
                                </b-col>
                                <b-col cols="12" v-show="global.pendingRequests <= 0">
                                    <b-row class="mb-5" v-if="confirmWeeksFormState.type == 2">
                                        <b-col sm="6" v-for="(label, index) in dataKeys" :key="index" class="text-left">
                                            <h5 class="m-0 p-0 mb-2" v-if="(_.find(dataSourceRequestsExtraCost, {week_number: label}))">
                                                Week: {{label}}
                                            </h5>
                                            <b-row v-for="(ds, dsIndex) in dataSourceRequestsExtraCost" :key="dsIndex" v-if="ds.week_number === label && (parseInt(ds.total_requested_vehicles) - (Math.round(parseFloat((ds.contractual_loads * ds.price.flexibility / 100) + parseInt(ds.contractual_loads)).toFixed(1)) * ds.price.loading_factors)) > 0" class="border-bottom mb-1">
                                                <b-col sm="12"><strong>{{ds.price.route.from_location.city}} - {{ds.price.route.to_location.city}} </strong></b-col>
                                                <b-col sm="8">Requested Loads (Vehicles):</b-col>
                                                <b-col sm="4">{{parseFloat(ds.total_requested_loads).toFixed(1)}} ({{ds.total_requested_vehicles}})</b-col>
                                                <b-col sm="8">Committed Loads (Vehicles):</b-col>
                                                <b-col sm="4">{{ds.contractual_loads}} ({{ds.contractual_vehicles}})</b-col>
                                                <b-col sm="8">Difference [Flex: {{ds.price.flexibility}}%] (Vehicles):</b-col>
                                                <b-col sm="4">
                                                    <span v-if="ds.total_requested_loads - ds.contractual_loads == 0" class="text-success">0 (0)</span>
                                                    <span v-if="ds.total_requested_loads - ds.contractual_loads > 0">
                                                            <span class="text-danger" v-if="ds.total_requested_loads - ds.contractual_loads > parseFloat(ds.contractual_loads * ds.price.flexibility / 100).toFixed(1)">
                                                                {{ parseFloat(ds.total_requested_loads - Math.round(parseFloat((ds.contractual_loads * ds.price.flexibility / 100) + parseInt(ds.contractual_loads)).toFixed(1))).toFixed(1) }}
                                                                ({{ parseInt(ds.total_requested_vehicles) - (Math.round(parseFloat((ds.contractual_loads * ds.price.flexibility / 100) + parseInt(ds.contractual_loads)).toFixed(1)) * ds.price.loading_factors)}})
                                                            </span>
                                                            <span class="text-success" v-else>0 (0)</span>
                                                        </span>
                                                    <span v-if="ds.total_requested_loads - ds.contractual_loads < 0" class="success">
                                                            <span class="text-info" v-if="ds.contractual_loads - ds.total_requested_loads > parseFloat(ds.contractual_loads * ds.price.flexibility / 100).toFixed(1)">
                                                                {{ parseFloat(ds.total_requested_loads - Math.round(parseFloat(parseInt(ds.contractual_loads) - (ds.contractual_loads * ds.price.flexibility / 100)).toFixed(1))).toFixed(1) }}
                                                                ({{ parseInt(ds.total_requested_vehicles) - (Math.round(parseInt(ds.contractual_loads) - parseFloat(ds.contractual_loads * ds.price.flexibility / 100)) * ds.price.loading_factors) }})
                                                            </span>
                                                            <span class="text-success" v-else>0 (0)</span>
                                                        </span>
                                                </b-col>
                                                <b-col sm="6" class="mt-2">
                                                    <b-form-group>
                                                        <b-form-input
                                                            v-model="ds.extra_cost_per_vehicle"
                                                            type="number"
                                                            :placeholder="$t('input.extraCostPerVehicle')"
                                                            @focusout="updatePriceTotalExtraCost(ds)"
                                                        ></b-form-input>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col sm="3" class="mt-2">
                                                    <b-form-group>
                                                        <b-form-input
                                                            readonly
                                                            v-model="ds.total_cost"
                                                            type="number"
                                                            :placeholder="$t('input.total')"
                                                        ></b-form-input>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col sm="3" class="mt-2">
                                                    <b-form-group>
                                                        <b-form-input
                                                            readonly
                                                            v-model="ds.currency"
                                                            type="text"
                                                            :placeholder="$t('input.currency')"
                                                        ></b-form-input>
                                                    </b-form-group>
                                                </b-col>
                                            </b-row>
                                        </b-col>
                                    </b-row><!--/b-row-->
                                    <b-row v-if="dataSourceRequests.length > 0">
                                        <b-col sm="12">
                                            <b-form-group
                                                :label="$t('input.comment')"
                                                label-for="comment"
                                                :invalid-feedback="formErrors.first('comments')">
                                                <b-textarea
                                                    id="comment"
                                                    v-model="confirmWeeksFormState.comments"
                                                    type="text"
                                                    :state="((formErrors.has('comments') ? false : null))"
                                                    @focus="$event.target.select()"
                                                ></b-textarea>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                </b-col><!--/b-col-->
                                <div class="drawer-footer">
                                    <b-row>
                                        <b-col sm="12">
                                            <b-button
                                                size="sm"
                                                class="ml-1"
                                                type="submit"
                                                variant="primary"
                                                accept_type="1"
                                                @click="handleDoubleConfirmWeeksSubmit()"
                                                :disabled="global.pendingRequests > 0"
                                                v-b-tooltip.hover :title="$t('button.title.confirm')"
                                            >
                                                <clip-loader style="display: inline" :loading="true" color="#fff"
                                                             size="12px"
                                                             v-if="global.pendingRequests > 0"></clip-loader>
                                                <i class="fa fa-save mr-1"></i>
                                                {{$t('button.confirm')}}
                                            </b-button>
                                            <b-button variant="warning" class="ml-1"
                                                      size="sm" @click="handleDoubleConfirmWeeksFormOperationClose()"
                                                      v-b-tooltip.hover :title="$t('button.title.cancel')">
                                                <i class="fa fa-arrow-left mr-1"></i> {{$t('button.cancel')}}
                                            </b-button>
                                        </b-col>
                                    </b-row>
                                </div>
                            </b-row><!--/b-row-->
                        </a-drawer>
                    </div><!--/.confirm-weeks-operation-->
                </b-tab>
                <b-tab :title="$t('title.list')" v-if="$global.hasPermission('orderdraftscanseelistsc')" :active="!$global.hasPermission('orderdraftscanseechartsc')">
                    <b-card-text>
                        <div class="card" v-show="operation !== 'detail'">
                            <div class="card-header card-header-flex pb-2">
                                <div class="w-100 mt-2">
                                    <div class="row">
                                        <div class="col-8">
                                            <h5 class="mt-3 ml-0 mr-3 mb-2">
                                                <strong>
                                                    <template v-if="operation === null">{{$t('title.orderDrafts')}}
                                                        <span v-if="pagination.total" >({{pagination.total}})</span>
                                                    </template>
                                                    <template v-else>{{ $t(operationTitle) }}</template>
                                                </strong>
                                            </h5>
                                        </div>
                                        <div class="col-4 text-right">
                                            <div v-if="operation === null">
                                                <div class="mt-3">
                                                    <!-- Using components -->
                                                    <b-input-group class="mt-3">
                                                        <b-form-input type="search" class="form-control form-control-sm"
                                                                      :placeholder="$t('input.whatAreYouLookingFor')"
                                                                      v-on:keyup.enter="handleSearch" v-model="search"></b-form-input>
                                                        <b-input-group-append>
                                                            <!--											<b-button @click="setOperation('add')" variant="info" size="sm"-->
                                                            <!--											          :title="$t('button.title.addNewItem')"-->
                                                            <!--											          v-if="$global.hasPermission('ordersstore')" v-b-tooltip.hover>-->
                                                            <!--												<i class="fe fe-plus"></i> {{$t('button.addNew')}}-->
                                                            <!--											</b-button>-->
                                                            <b-button size="sm" :title="$t('button.title.filterRecords')"
                                                                      variant="outline-info"
                                                                      @click="filters.visible = !filters.visible" v-b-tooltip.hover
                                                                      v-if="$global.hasPermission('ordersview')">
                                                                <i class="fa fa-filter"></i>
                                                            </b-button>
                                                            <b-button size="sm" :title="$t('button.title.resetList')"
                                                                      variant="outline-info"
                                                                      @click="handleResetFilterClick()" v-b-tooltip.hover>
                                                                <i class="fa fa-refresh"></i>
                                                            </b-button>
                                                        </b-input-group-append>
                                                    </b-input-group>
                                                </div>
                                            </div>
                                            <div v-else>
                                                <b-button variant="warning" size="sm" class="mt-3"
                                                          @click="handleOperationClose()"
                                                          v-b-tooltip.hover :title="$t('button.title.cancel')">
                                                    <i class="fa fa-arrow-left"></i> {{$t('button.cancel')}}
                                                </b-button>
                                            </div>
                                        </div>
                                    </div><!-- /.row -->
                                </div><!-- /.w-100 -->
                            </div><!-- /.card-header -->
                            <div class="card-body p-0">
                                <div class="order-drafts-table">
                                    <b-table hover responsive
                                             ref="table"
                                             :busy="listingLoading"
                                             :items="dataSource"
                                             :fields="columns"
                                             :sort-by.sync="sortField"
                                             @sort-changed="handleSortChange">
                                        <template v-slot:cell(dealer_id)="{ detailsShowing, item, index, rowSelected}">
                                            <template v-if="rowSelected">
                                                <span aria-hidden="true">&check;</span>
                                            </template>
                                            <a @click="toggleDetails(item)" class="d-inline in-center">
                                                <i :class="[{'fa fa-plus': !detailsShowing}, {'fa fa-minus': detailsShowing}]"></i>
                                            </a>
                                            {{ (item.dealer_id ? item.dealer_id : '') }}
                                        </template>
                                        <template v-slot:cell(client_id)="record">
                                            {{ (record.item.client ? record.item.client.company_name : '') }}
                                        </template>
                                        <template v-slot:cell(order_type)="record">
                                            <od-type :type="record.item.order_type"/>
                                        </template>
                                        <template v-slot:cell(status)="record">
                                            <od-status :status="record.item.status"/>
                                        </template>
                                        <template v-slot:row-details="{ item }">
                                            <b-card>
                                                <b-list-group flush>
                                                    <b-list-group-item>
                                                        <strong>{{$t('column.orderImportErrors')}}</strong>:
                                                        {{item.order_error_notes}}
                                                    </b-list-group-item>
                                                </b-list-group>
                                            </b-card>
                                        </template>
                                    </b-table><!-- /.b-table -->

<div class="clearfix">
    <div class="float-left ">
        <b-form-select v-model="pagination.perPage" :options="[5, 10, 50, 100, 1000]" size="sm"></b-form-select>
    </div>
    <div class="float-right">
        <b-pagination
                v-model="pagination.current"
                :total-rows="pagination.total"
                :per-page="pagination.perPage"
                :first-text="$t('paginations.first')"
                :prev-text="$t('paginations.prev')"
                :next-text="$t('paginations.next')"
                :last-text="$t('paginations.last')"
        ></b-pagination>
    </div><!-- /.pull-right -->
</div><!-- /.clearfix -->
                                </div><!-- /.order-drafts-table -->
                                <div class="order-drafts-operation">
                                    <a-drawer
                                        placement="right"
                                        :width="'950px'"
                                        :wrapStyle="{overflow: 'auto',paddingBottom: '108px'}"
                                        :closable="false"
                                        @close="handleOperationClose"
                                        :visible="operation !== null && operation !== 'detail'"
                                        :zIndex="10"
                                        :title="$t(operationTitle)"
                                    >
                                        <form @submit.prevent="handleSubmit" autocomplete="off">
                                            <b-row>
                                                <div class="drawer-footer">
                                                    <b-button
                                                        size="sm"
                                                        type="submit"
                                                        variant="primary"
                                                        :disabled="global.pendingRequests > 0"
                                                        v-b-tooltip.hover :title="$t('button.title.save')"
                                                    >
                                                        <clip-loader style="display: inline" :loading="true" color="#fff"
                                                                     size="12px"
                                                                     v-if="global.pendingRequests > 0"></clip-loader>
                                                        <i class="fa fa-save mr-1"></i>
                                                        {{$t('button.save')}}
                                                    </b-button>
                                                    <b-button variant="warning" class="ml-3"
                                                              size="sm" @click="handleOperationClose()"
                                                              v-b-tooltip.hover :title="$t('button.title.cancel')">
                                                        <i class="fa fa-arrow-left mr-1"></i> {{$t('button.cancel')}}
                                                    </b-button>
                                                </div>
                                            </b-row><!--/b-row-->
                                        </form><!--/form-->
                                    </a-drawer>
                                </div><!--/.order-drafts-operation-->
                                <div class="filter-container">
                                    <a-drawer
                                        placement="left"
                                        :width="'360px'"
                                        :wrapStyle="{overflow: 'auto',paddingBottom: '108px'}"
                                        :closable="false"
                                        @close="filters.visible = !filters.visible"
                                        :visible="!operation && filters.visible"
                                        :zIndex="10"
                                        :title="$t('title.advanceFilters')"
                                    >
                                        <form @submit.prevent="loadList(listQueryParams, true)" autocomplete="off">
                                            <b-row>
                                                <b-col sm="12">
                                                    <b-form-group
                                                        :label="$t('input.fromAddedDate')"
                                                        label-for="fromDate">
                                                        <b-form-datepicker placeholder="" id="fromDate" v-model="filters.from_date"
                                                                           class="mb-2"></b-form-datepicker>
                                                    </b-form-group>
                                                </b-col><!--/b-col-->
                                                <b-col sm="12">
                                                    <b-form-group
                                                        :label="$t('input.toAddedDate')"
                                                        label-for="toDate">
                                                        <b-form-datepicker placeholder="" id="toDate" v-model="filters.to_date"
                                                                           class="mb-2"></b-form-datepicker>
                                                    </b-form-group>
                                                </b-col><!--/b-col-->
                                            </b-row>
                                            <div class="drawer-footer">
                                                <b-button size='sm' variant="info" @click="filters.visible = !filters.visible"
                                                          class="mr-2" :title="$t('button.title.closePanel')" v-b-tooltip.hover>
                                                    {{$t('button.close')}}
                                                </b-button>
                                                <b-button size='sm' variant="warning" @click="handleResetFilterClick" class="mr-2"
                                                          :title="$t('button.title.resetFilter')" v-b-tooltip.hover>
                                                    {{$t('button.reset')}}
                                                </b-button>
                                                <b-button size='sm' variant="primary" button="submit" type="filled"
                                                          :title="$t('button.title.filterRecords')" v-b-tooltip.hover>
                                                    {{$t('button.apply')}}
                                                </b-button>
                                            </div><!-- /.drawer-footer -->
                                        </form>
                                    </a-drawer>
                                </div><!-- /.filter-container -->
                            </div><!-- /.card-body-->
                        </div><!-- /.card -->
                    </b-card-text>
                </b-tab>
            </b-tabs>
        </b-card>
        <div v-if="operation === 'detail'">
            <detail :handle-close-operation="handleOperationClose"></detail>
        </div>

        <!-- # Excel Export -->
        <b-modal id="excel-export-modal" v-model="exportModal.visibility" hide-footer>
            <template #modal-title>
                {{$t('title.exportExcel')}}
            </template>
            <div>
                <form @submit.prevent="handleExcelExportSubmitClick">
                    <div class="mb-5">
                        <b-form-group>
                            <a href="javascript:;" class="p-3" @click="handleExcelExportSelectAllClick">{{$t('input.checkAll')}}</a>
                            <a href="javascript:;" class="p-3" @click="handleExcelExportUnSelectAllClick">{{$t('input.unCheckAll')}}</a>
                        </b-form-group>
                        <hr>
                        <table class="table table-sm">
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>{{$t('title.columns')}}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(column, index) in exportModal.columns">
                                <td>{{index + 1}}</td>
                                <td>
                                    <b-form-checkbox v-model="column.show"
                                                     name="check-button">{{column.label}}
                                    </b-form-checkbox>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="drawer-footer">
                        <json-excel
                            class="btn btn-primary btn-sm"
                            :fields="exportModal.headers"
                            worksheet="ATNs"
                            :fetch="handleExcelExportSubmitClick"
                            name="atns.xls">
                            <clip-loader style="display: inline" :loading="true" color="#fff"
                                         size="12px" v-if="global.pendingRequests > 0"></clip-loader>
                            <i class="fa fa-save mr-1"></i>
                            {{$t('button.export')}}
                        </json-excel>
                        <b-button variant="warning" class="ml-3"
                                  size="sm" @click="handleExcelExportCancelClick()"
                                  v-b-tooltip.hover :title="$t('button.title.cancel')">
                            <i class="fa fa-arrow-left mr-1"></i> {{$t('button.cancel')}}
                        </b-button>
                    </div>
                </form>
            </div>
        </b-modal><!--/#excel-export-modal-->
	</div>
</template>
<script>
    import ListingMixin from '../../../util/ListingMixin'
    import Error from '../../../util/Error'
    import {mapState} from 'vuex'
    import Datepicker from 'vuejs-datepicker'
    import {request} from '../../../util/Request'
    import Treeselect from '@riophae/vue-treeselect'
    import Detail from '../detail'
    import ReportMixin from "../ReportMixin";
    import ConfirmWeeksMixin from "./ConfirmWeeksMixin";
    import ExcelExportMixin from "./ExcelExportMixin";
    import JsonExcel from "vue-json-excel"
    import Vue from "vue";

    const FORM_STATE = {
        _method: 'post',
    };

    const FILTER_STATE = {
        visible: false,
        from_date: null,
        to_date: null,
    };

    const COLUMN_DEFINITION = (self) => [
        {
            label: self.$t('column.dealerId'),
            key: 'dealer_id',
            sortable: true,
            stickyColumn: true,
            sortKey: 'dealer_id',
        },
        {
            label: self.$t('column.client'),
            key: 'client_id',
            sortable: true,
            sortKey: 'client_id',
        },
        {
            label: self.$t('column.orderType'),
            key: 'order_type',
            sortable: true,
            sortKey: 'order_type',
        },
        {
            label: self.$t('column.orderCode'),
            key: 'order_code',
            sortable: true,
            sortKey: 'order_code',
        },
        {
            label: self.$t('column.brandName'),
            key: 'brand_name',
            sortable: true,
            sortKey: 'brand_name',
        },
        {
            label: self.$t('column.modelCode'),
            key: 'model_code',
            sortable: true,
            sortKey: 'model_code',
        },
        {
            label: self.$t('column.versionType'),
            key: 'version_type',
            sortable: true,
            sortKey: 'version_type',
        },
        {
            label: self.$t('column.status'),
            key: 'status',
            sortable: true,
            sortKey: 'status',
        },
    ];

    export default {
        mixins: [ListingMixin, ReportMixin, ExcelExportMixin, ConfirmWeeksMixin],
        components: {
            Datepicker,
            Detail,
            Treeselect,
            JsonExcel
        },
        data() {
            return {
                operationTitle: 'title.orderDrafts',
                formFields: {...FORM_STATE},
                editOrderDraft: null,
                filters: {...FILTER_STATE},
                listUrl: 'order/drafts/carrier/supplier',
                columns: COLUMN_DEFINITION(this),
                dropdowns: {
                    brands: [],
                    models: [],
                    countries: [],
                    years: ['2018', '2019', '2020', '2021', '2022', '2024'],
                    months: ['01','02','03','04','05','06','07','08','09','10','11','12'],
                },
                show: true,
                chartUrl: 'order/drafts/carrier/supplier/week/report',
            }
        },
        mounted() {
            this.$title = this.$t('topBar.navigations.modules.orderDrafts')
            this.handleConfirmWeeks(false);
            this.getCountries();
            this.getBrands();
            this.getModels();
            this.getDealers();
            this.handleReportFilterSubmit();

            if (this.$route.query && this.$route.query.operation && this.$route.query.oToken) {
                // this.handleEditClick(this.$route.query.oToken)
            }
        },
        methods: {
            toggleDetails(row) {
                this.$set(row, '_showDetails', ((row._showDetails) ? !row._showDetails : true))
            },
            setOperation(operation = 'add', operationToken = null) {
                // this.operationTitle = (operation === 'add' ? 'title.addBrand' : 'title.editBrand')
                this.$router.replace({
                    query: Object.assign({},
                        this.$route.query,
                        {
                            ...this.listQueryParams,
                            operation: operation,
                            oToken: operationToken,
                        },
                    ),
                }).then(() => {
                }).catch(() => {
                })
            },
            async handleSubmit() {

            },
            async handleEditClick(id) {

            },
            async getCountries() {
                try {
                    const response = await request({
                        url: '/dropdowns/countries',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.countries = data

                } catch (e) {
                    this.dropdowns.countries = []
                }
            },
            async getDealers() {
                try {
                    const response = await request({
                        url: '/dropdowns/dealers',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.dealers = _.map(data, (item) =>
                        ({
                            ...item,
                            id: item.dealer_id,
                            label: item.label,
                        })
                    )
                } catch (e) {
                    this.dropdowns.dealers = []
                    this.dropdowns.dealersLocations = []
                }
            },
            async getBrands() {
                try {
                    const response = await request({
                        url: '/dropdowns/brands',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.brands = data
                } catch (e) {
                    this.dropdowns.brands = []
                }
            },
            async getModels() {
                try {
                    const response = await request({
                        url: '/dropdowns/models',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.models = data.map(item => ({
                        ...item,
                        label: `${item.label} - ${item.version_code}`,
                    }))

                } catch (e) {
                    this.dropdowns.models = []
                }
            },
            hasListAccess() {
                return this.$global.hasPermission('orderdraftscanseelistsc')
            },
            getExtraParams() {
                return {
                    filters: {
                        ...this.filters,
                        from_date: ((this.filters.from_date) ? this.$global.dateToUtcDate(this.filters.from_date, 'YYYY-MM-DD', 'YYYY-MM-DD') : ''),
                        to_date: ((this.filters.to_date) ? this.$global.dateToUtcDate(this.filters.to_date, 'YYYY-MM-DD', 'YYYY-MM-DD') : ''),
                    },
                }
            },
			handleResetFilterClick() {
				this.filters = {...FILTER_STATE}
				this.isFilterApplied = 'reset'
				this.handleResetClick()
				this.loadList(this.listQueryParams)
			},
            afterCloseOperation() {
                this.formFields = {...FORM_STATE}
            },
            handleTabChange() {

            }
        },
        computed: {
            ...mapState([
                'global',
                'settings'
            ]),
        },
    }
    Vue.component('country-sum', {
        template: '<span>({{ sum }})</span>',
        props: ['country'],
        data() {
            return {sum: 0}
        },
        watch: {
            country: {
                immediate: true,
                handler(val) {
                    _.map(val.items, (val1, ind1) => {
                        _.map(val1.items, (val2, ind2) => {
                            _.map(val2.items, (val3, ind3) => {
                                this.sum += val3.total_orders
                            })
                        })
                    })
                }
            }
        }
    });

    Vue.component('pick-up-sum', {
        template: '<span>({{ sum }})</span>',
        props: ['item'],
        data() {
            return {sum: 0}
        },
        watch: {
            item: {
                immediate: true,
                handler(val) {
                    _.map(val, (val1, ind1) => {
                        _.map(val1.items, (val2, ind2) => {
                            this.sum += val2.total_orders
                        })
                    })
                }
            }
        }
    });
</script>
<style lang="css">
    .nav.nav-pills.card-header-pills.nav-fill{
        border:1px solid #e2e2e2;
    }

    .card-header{
        background-color: rgba(0,0,0,.03);
    }

    .nav-item{
        background-color: white;
    }

    .text-size-1-2em{
        font-size: 1.2em;
    }

    .collapsed .when-open,
    .not-collapsed .when-closed {
        display: none;
    }

    .fs-13 {
        font-size: 13px;
    }
</style>
