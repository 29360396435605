import {request} from '../../../util/Request'
import _ from "lodash";
import moment from "moment-timezone";

const CONFIRM_WEEKS_FORM_STATE = {
    weeks: [],
    weeklyRequests: [],
    extra_costs: [],
    comments: null,
    type: null,
}

export default {
    data() {
        return {
            dataKeys: [],
            dataSourceRequests: [],
            dataSourceRequestsExtraCost: [],
            confirmWeeksFormVisible: false,
            doubleConfirmWeeksFormVisible: false,
            confirmWeeksFormState: {...CONFIRM_WEEKS_FORM_STATE},
            doubleConfirmOperationTitle: '',
            accept_full: 0,
            accept_contractual: 0,
            accept_contractual_difference: 0,
            cancel: 0,
            efl_status: '',
        }
    },
    methods: {
        async handleConfirmWeeks(showPopup = true) {
            try {
                const response = await request({
                    url: 'order/drafts/carrier/supplier/confirm/weeks',
                    method: 'get',
                })

                const {data, keys} = response.data
                this.dataKeys = keys
                this.dataSourceRequests = data
                this.confirmWeeksFormState = CONFIRM_WEEKS_FORM_STATE;
                this.confirmWeeksFormState.weeks = keys
                this.confirmWeeksFormState.weeklyRequests.push({
                    price_ids: this.dataSourceRequests.map((item) => item.price_id),
                    week_numbers: this.dataSourceRequests.map((item) => item.week_number)
                })
                this.dataSourceRequestsExtraCost = [];
                _.map(data, (item) => {
                    this.accept_full = item.accept_full
                    this.accept_contractual = item.accept_contractual
                    this.accept_contractual_difference = item.accept_contractual_difference
                    this.cancel = item.cancel
                    if((parseInt(item.total_requested_vehicles) - (Math.round(parseFloat((item.contractual_loads * item.price.flexibility / 100) + parseInt(item.contractual_loads)).toFixed(1)) * item.price.loading_factors)) > 0) {
                        this.dataSourceRequestsExtraCost.push(item);
                        this.efl_status = item.efl_status
                    }
                })
            } catch (error) {

            }
            if(showPopup) {
                this.confirmWeeksFormVisible = true
            }
        },
        async handleConfirmWeeksSubmit(type) {
            this.doubleConfirmWeeksFormVisible = true
            this.confirmWeeksFormState.type = type;
            switch (type) {
                case 1:
                    this.doubleConfirmOperationTitle = this.$t('button.fullAcceptanceWithExtraCost')
                    break;
                case 2:
                    this.doubleConfirmOperationTitle = this.$t('button.fullAcceptanceWithOffer')
                    break;
                case 3:
                    this.doubleConfirmOperationTitle = this.$t('button.contractualAcceptance')
                    break;
                case 4:
                    this.doubleConfirmOperationTitle = this.$t('button.reject')
                    break;
            }
        },
        async handleDoubleConfirmWeeksSubmit() {
            try {
                const response = await request({
                    url: 'order/drafts/carrier/supplier/confirm/weeks',
                    method: 'post',
                    data: {
                        ...this.confirmWeeksFormState,
                        extra_costs: this.dataSourceRequests.map((item) => ({
                            price_id: item.price_id,
                            week_number: item.week_number,
                            extra_cost_per_vehicle: item.extra_cost_per_vehicle > 0 ? item.extra_cost_per_vehicle : 0,
                            total_cost: item.total_cost > 0 ? item.total_cost : 0,
                        })),
                    },
                })

                this.itemUpdated()
                this.handleConfirmWeeksFormOperationClose()
            } catch (error) {
                this.handleServerError(error)
            }
            this.handleConfirmWeeks(false)
        },
        updatePriceTotalExtraCost(item) {
            _.map(this.dataSourceRequests, (ds, index) => {
                if(ds.id === item.id) {
                    ds.total_cost = parseFloat(ds.non_contractual_vehicles * item.extra_cost_per_vehicle).toFixed(2);
                }
            })
        },
        handleConfirmWeeksFormOperationClose() {
            this.confirmWeeksFormState = {...CONFIRM_WEEKS_FORM_STATE}
            this.confirmWeeksFormVisible = false
            this.doubleConfirmWeeksFormVisible = false
            this.dataKeys.length = 0
            this.dataSourceRequests = 0
            this.dataSourceRequestsExtraCost = 0
            this.weekAcceptance = 0
            this.handleReportFilterSubmit()
        },

        handleDoubleConfirmWeeksFormOperationClose() {
            this.confirmWeeksFormState.type = null
            this.confirmWeeksFormState.comments = null
            this.confirmWeeksFormState.extra_costs = []
            this.doubleConfirmWeeksFormVisible = false
        },
    }
}
